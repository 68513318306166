export const adOnboardingSteps = [
  {
    path: "/ad-onboarding/step-1",
    header: "Step 1: What To Expect From Onboarding 🤷🏻‍♂️",
    vimeoUrl: "https://player.vimeo.com/video/1029120130",
    description:
      "Please Note: The on-boarding process takes around 90 minutes, please leave adequate time for this. This is the most efficient marketing on-boarding process you'll ever come across. \n\nIt may take 90 min, but once it's done... it's done. No back and forth emails or stumbling for passwords further down the line.",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/ad-onboarding/step-2",
  },
  {
    path: "/ad-onboarding/step-2",
    header: "Step 2: What To Expect From The 90 Days 🏅",
    vimeoUrl: "",
    description: "",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/ad-onboarding/step-3",
  },
  {
    path: "/ad-onboarding/step-3",
    header: "Step 3: Sign Contract & Pay Invoice ⚡️",
    vimeoUrl: "https://player.vimeo.com/video/1029120250",
    description: "",
    secondaryHeader: "Sign the Contract",
    headlineCalloutText: "Here",
    headlineLink: "",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/ad-onboarding/step-4",
  },
  {
    path: "/smm-onboarding/step-4",
    header: "Step 4: Time For Ads Manager Integration! 💥",
    vimeoUrl: "https://player.vimeo.com/video/1029855707",
    description: "",
    secondaryHeader: "Ad ID: 2499636336910980",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/ad-onboarding/step-5",
  },
  {
    path: "/ad-onboarding/step-5",
    header: "Step 5: Tell Us About Your Audience! 😎",
    vimeoUrl: "https://player.vimeo.com/video/1029120367",
    description: "",
    secondaryHeader: "Time To Tell Us About Your Audience: ",
    headlineCalloutText: "Here",
    headlineLink: "https://forms.gle/D6eXUeqYk9FK8irK8",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/ad-onboarding/step-6",
  },
  {
    path: "/ad-onboarding/step-6",
    header: "Step 6: Book In Your Strategy Session 🙌",
    vimeoUrl: "https://player.vimeo.com/video/1029120494",
    description: "",
    secondaryHeader: "Book a Strategy Session ",
    headlineCalloutText: "Here",
    headlineLink:
      "https://calendly.com/nathanblee/paid-traffic-client-strategy-session",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/ad-onboarding/step-7",
  },
  {
    path: "/ad-onboarding/step-7",
    header: "Step 7: Just Some Final Words ⭐️",
    vimeoUrl: "https://player.vimeo.com/video/1029119995",
    description: "",
    secondaryHeader:
      "Congratulations! You are done and can close out this page",
  },
];
